$main-sm-columns:       					12;
$sidebar-sm-columns:    					4;

$font-size-base:							16px;
$font-family-sans-serif:					"futura-pt",sans-serif;

$brand-primary:								#10578a;
$brand-secondary:							#ffedb9;
$brand-tertiary:							#d48d29;

$body-bg:									$brand-secondary;