#info {
	text-align: center;
	.nav {
		float: none;
		margin: 0 auto;
		text-transform: uppercase;
		> li {
			float: none;
			display: inline-block;
			> a {
				color: $text-color;
				&:hover,
				&:active {
					color: $link-color;
					background: transparent;
				}
			}
			&.active {
				> a {
					color: $link-color;
					background: transparent;
					&:hover,
					&:active {
						color: $link-color;
						background: transparent;
					}
				}				
			}
		}
	}
}