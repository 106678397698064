.home {
	#wrap {
		background: url(../images/slider-1.jpg) no-repeat center top;
		background-size: cover;
	}
}

#slider {
	border-bottom: 25px solid $brand-primary;
	.container-fluid {
		.lead {
			padding-top: 45px;
			color: $brand-primary;
		}
	}
}

#page-header {
	@media only screen and (min-width:768px) {
		border-top: 25px solid $brand-primary;
	}
}

#intro {
	box-shadow: 0 0 15px rgba(0,0,0,0.5);
	background: rgba(212,140,41,0.9);
	z-index: 900;
	img {
		border: 1px solid #fff;
		max-width: 75%;
		margin: 0 auto;
	}
	a {
		color: #fff;
		&:hover {
			color: $brand-secondary;
		}
	}
	h4 {
		@media only screen and (min-width:768px) {
			margin-bottom: 0;
		}
	}
}

#content {
	color: $link-color;
	background: url(../images/bg-body.png) no-repeat center top;
	background-size: contain;
	a {
		color: $brand-tertiary;
	}
	h1 {
		@media only screen and (max-width:767px) {
			text-align: center;
		}
		@media only screen and (min-width:768px) {
			text-indent: 50px;
		}
		@media only screen and (min-width:992px) {
			text-indent: 55px;
		}
		@media only screen and (min-width:1200px) {
			text-indent: 55px;
		}
	}
	.page-content {
		@media only screen and (min-width:768px) {
			padding: 0 50px;
		}
		@media only screen and (min-width:992px) {
			padding: 0 55px;
		}
		@media only screen and (min-width:1200px) {
			padding: 0 55px;
		}
	}
	.page-downloads {
		@media only screen and (max-width:767px) {
			.text-right {
				text-align: center;
				.pull-right {
					float: none !important;
					margin: 0 auto;
				}
			}
		}
		@media only screen and (min-width:768px) {
			padding-right: 50px;
		}
		@media only screen and (min-width:992px) {
			padding-right: 55px;
		}
		@media only screen and (min-width:1200px) {
			padding-right: 55px;
		}
	}
}

.wpcf7 {
	padding: 10px !important;
	background: rgba(255,255,255,0.65);
	border-radius: 5px;
	.form-group {
		background: #fff;
		border-radius: 5px;
		padding: 5px 8px !important;
		margin-bottom: 10px;
		label {
			text-transform: uppercase;
			font-family: "futura-pt-condensed",sans-serif;
			font-weight: normal;
			font-size: 16px;
			padding: 0 0 0 0;
			margin: 0 0 0 0;
			small {
				color: $text-muted;
			}
		}
		.form-control {
			border: 0px none;
			box-shadow: none;
			padding: 0 10px;
			margin: 0 0 0 0;
		}
	}
}