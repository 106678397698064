.navbar {
	border: 0px none;
	&.navbar-inverse {
		background: $brand-primary;
		.navbar-toggle {
			color: $brand-secondary;
			&:hover,
			&:active,
			&:focus {
				color: #fff;
				background: darken($brand-primary,5%);
			}
		}
	}
	&.navbar-default {
		background: $brand-secondary;
		.nav {
			> li {
				a {
					color: $brand-primary;
					&:hover {
						color: $brand-tertiary;
					}
				}
				&.active {
					> a {
						color: $brand-tertiary;
						background: transparent;
					}
				}
			}
		}
	}
	margin-bottom: 0;
	.navbar-nav {
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0.01em;
		&.navbar-left,
		&.navbar-right {
			@media only screen and (min-width:768px) {
				margin: 0 -15px;
			}
			@media only screen and (min-width:992px) {
				margin: 0 -20px;
			}
			@media only screen and (min-width:1200px) {
				margin: 0 -30px;
			}
		}
		> li {
			> a {
				@media only screen and (min-width:768px) {
					padding: 14px 15px;
				}
				@media only screen and (min-width:992px) {
					padding: 14px 20px;
				}
				@media only screen and (min-width:1200px) {
					padding: 14px 30px;
				}
			}
		}
		&#nav-social {
			font-size: 18px;
			> li {
				@media only screen and (max-width:767px) {
					float: left;
				}
				> a {
					color: #fff;
					&:hover {
						color: $brand-secondary;
					}
				}
			}
		}
	}
	.navbar-brand {
		text-transform: uppercase;
		font-weight: 300;
		color: #fff;
		font-family: "futura-pt-condensed",sans-serif;
		font-weight: normal;
		line-height: 50px;
		padding: 0 15px;
		letter-spacing: 0.05em;
		font-size: 18px;
		@media only screen and (min-width:768px) {
			font-size: 36px;
		}
		@media only screen and (min-width:992px) {
			font-size: 44px;
		}
		@media only screen and (min-width:1200px) {
			font-size: 48px;
		}
		&:hover {
			color: $brand-secondary;
		}
	}
	.navbar-toggle {
		margin: 0 0 0 0;
		padding: 15px;
		border: 0px none;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 0.01em;
		border-radius: 0 0 0 0;
	}
	&#navbar-main {
		min-height: 0;
	}
}