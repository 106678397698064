/** VARIABLES 
===================================*/
$controls: '../images/controls.png';
$loader:   '../images/bx_loader.gif';

/** RESET AND LAYOUT
===================================*/

.bx-wrapper {
	position: relative;
	margin: 0 auto;
	padding: 0;
	*zoom: 1;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	img {
		max-width: 100%;
		display: block;
	}
}

.bxslider {
	margin: 0;
	> li {
		min-height: calc(100vh - 125px);
	  	margin: 0;
	  	padding: 15px 0 0 0;
		background-size: 1300px auto !important;
		background-position: top center !important;
		background-repeat: no-repeat !important;
	}
}

ul.bxslider {
	list-style: none;
}

.bx-viewport {
	/*fix other elements on the page moving (on Chrome)*/
	-webkit-transform: translatez(0);
}

/** THEME
===================================*/

.bx-wrapper {
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 0px none;
	background: transparent;
	.bx-pager,
	.bx-controls-auto {
		position: absolute;
		bottom: 30px;
		width: 100%;	
	}
	
	/* LOADER */
	.bx-loading {
		min-height: 50px;
		background: url($loader) center center no-repeat #fff;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2000;
	}
	
	/* PAGER */
	.bx-pager {
		text-align: center;
		font-size: .85em;
		font-family: Arial;
		font-weight: bold;
		color: #fff;
		padding-top: 20px;
		z-index: 999;
		&.bx-default-pager a {
			background: #fff;
			text-indent: -9999px;
			display: block;
			width: 10px;
			height: 10px;
			margin: 0 5px;
			outline: 0;
			-moz-border-radius: 5px;
			-webkit-border-radius: 5px;
			border-radius: 5px;
			&:hover,
			&.active,
			&:focus {
				background: $brand-tertiary;
			}
		}
	}
	.bx-pager-item,
	.bx-controls-auto .bx-controls-auto-item {
		display: inline-block;
		*zoom: 1;
		*display: inline;
	}
	.bx-pager-item {
		font-size: 0;
		line-height: 0;
	}
	
	/* DIRECTION CONTROLS (NEXT / PREV) */	
	.bx-prev {
		left: 0;
		background: url($controls) no-repeat 0 -30px;
		&:hover,
		&:focus {
			background-position: 0 0;
		}
	}
	.bx-next {
		right: 0;
		background: url($controls) no-repeat -30px -30px;
		&:hover,
		&:focus {
			background-position: -30px 0;
		}
	}
	.bx-controls-direction a {
		position: absolute;
		bottom: 0;
		outline: 0;
		width: 30px;
		height: 30px;
		text-indent: -9999px;
		z-index: 1000;
		&.disabled {
			display: none;
		}
	}
		
	/* AUTO CONTROLS (START / STOP) */
	.bx-controls-auto {
		text-align: center;
		.bx-start {
			display: block;
			text-indent: -9999px;
			width: 10px;
			height: 11px;
			outline: 0;
			background: url($controls) -86px -11px no-repeat;
			margin: 0 3px;
			&:hover,
			&.active,
			&:focus {
				background-position: -86px 0;
			}
		}
		.bx-stop {
			display: block;
			text-indent: -9999px;
			width: 9px;
			height: 11px;
			outline: 0;
			background: url($controls) -86px -44px no-repeat;
			margin: 0 3px;
			&:hover,
			&.active,
			&:focus {
				background-position: -86px -33px;
			}
		}
	}
	
	/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
	.bx-controls.bx-has-controls-auto.bx-has-pager {
		.bx-pager {
			text-align: left;
			width: 80%;
		}
		.bx-controls-auto {
			right: 0;
			width: 35px;
		}
	}
	
	/* IMAGE CAPTIONS */
	.bx-caption {
		position: absolute;
		bottom: 0;
		left: 0;
		background: #666;
		background: rgba(80, 80, 80, 0.75);
		width: 100%;
		span {
			color: #fff;
			font-family: Arial;
			display: block;
			font-size: .85em;
			padding: 10px;
		}
	}
}
