body,
nav,
header,
section,
footer,
.container {
  position: relative;
}

.section {
	padding: 30px 0;
	&.section-white {
		background: #fff;
		hr, .page-header {
			border-color: #eee;
		}
	}
	&.section-light {
		background: $brand-secondary;
		hr, .page-header {
			border-color: #eee;
		}
	}
	&.section-dark {
		background: $brand-tertiary;
		color: #fff;
		hr, .page-header {
			border-color: rgba(0,0,0,0.1);
		}
	}
	&.section-gray {
    background: #f5f5f5;
		hr, .page-header {
			border-color: #eee;
		}
	}
	&.section-black {
		background: #000;
		color: #fff;
		hr, .page-header {
			border-color: rgba(0,0,0,0.1);
		}
	}
	&.section-lg {
  		padding: 60px 0;
		min-height: 100vh;
	}
}

.center-vert {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.widget {
  margin-bottom: 30px;
}

hr {
  margin: 30px 0;
}

h4 {
	text-transform: uppercase;
}

.lead {
	font-weight: 400;
}

blockquote {
	border-color: $brand-tertiary;
}